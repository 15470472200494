<template>
  <div id="product" itemscope itemtype="http://schema.org/Product">
    <OProductDetails
      :product="getCurrentProduct"
      :product-gallery="getProductGallery"
      :product-configuration="getCurrentProductConfiguration"
      :product-custom-options="getCurrentCustomOptions"
      :product-attributes="getCustomAttributes"
      :product-stock="stock"
      :is-rendered-by-s-s-r="isRenderedBySSR"
      :is-ready-to-send-g-t-m-events="isReadyToSendGTMEvents"
      :below-add-to-cart-block="belowAddToCartBlock"
    />
    <div class="product__bottom">
      <lazy-hydrate when-visible>
        <MRecommendedProducts :header="$t('We found other products you might like')" slider-id="top" :products="youMightLikeCarousel" />
      </lazy-hydrate>
      <!-- anton är bäst
      <lazy-hydrate when-visible>
        <GetFlowboxProduct :product-sku="getCurrentProduct.parentSku" />
      </lazy-hydrate>
      -->
      <lazy-hydrate when-visible>
        <SfSection v-show="banners.length" class="section">
          <router-link :key="i" :to="banner.link" v-for="(banner, i) in banners">
            <SfBanner
              :subtitle="banner.subtitle"
              :title="banner.title"
              :image="banner.image"
              :description="banner.description"
              :button-text="$t('Shop now')"
              class="banner sf-banner--slim"
              :class="{'banner-reverse': (i % 2)}"
            />
          </router-link>
        </SfSection>
      </lazy-hydrate>
      <lazy-hydrate when-visible>
        <MRecommendedProducts :header="$t('Similar Products')" slider-id="bottom" :products="similarCarousel" />
      </lazy-hydrate>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { htmlDecode } from '@vue-storefront/core/filters';
import { onlineHelper, isServer } from '@vue-storefront/core/helpers';
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks';
import OProductDetails from '~/theme/components/organisms/o-product-details';
import SfSection from '@storefront-ui/vue/src/components/molecules/SfSection/SfSection.vue';
import SfBanner from '@storefront-ui/vue/src/components/molecules/SfBanner/SfBanner.vue';
import ProductReviewsMixin from '~/theme/mixins/product-reviews.js';
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers';
import config from 'config';
import { filterChangedProduct } from '@vue-storefront/core/modules/catalog/events';
import TrackingMixin from '~/theme/mixins/tracking/tracking.ts'
const MRecommendedProducts = () => import(/* webpackChunkName: "m-recommended-products" */'~/theme/components/molecules/m-recommended-products');

export default {
  name: 'Product',
  components: {
    LazyHydrate,
    MRecommendedProducts,
    SfSection,
    OProductDetails,
    SfBanner
  },
  mixins: [TrackingMixin, ProductReviewsMixin],
  provide () {
    return {
      configurableOptionCallback: this.configurableOptionCallback
    }
  },
  data () {
    return {
      stock: {
        isLoading: false,
        max: 0,
        manageQuantity: true
      },
      isRenderedBySSR: false,
      isReadyToSendGTMEvents: false,
      youMightLikeCarousel: [],
      similarCarousel: []
    };
  },
  computed: {
    ...mapState({
      isWebpSupported: state => state.ui.isWebpSupported
    }),
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCurrentProduct: 'product/getCurrentProduct',
      getProductGallery: 'product/getProductGallery',
      getCurrentProductConfiguration: 'product/getCurrentProductConfiguration',
      getOriginalProduct: 'product/getOriginalProduct',
      attributesByCode: 'attribute/attributeListByCode',
      getCurrentCustomOptions: 'product/getCurrentCustomOptions',
      getBreadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes',
      getBreadcrumbsCurrent: 'breadcrumbs/getBreadcrumbsCurrent',
      cartItems: 'cart/getCartItems',
      cmsBlocks: 'cmsBlock/getCmsBlocks'
    }),
    breadcrumbs () {
      return this.getBreadcrumbsRoutes
        .map(route => ({
          text: htmlDecode(route.name),
          route: {
            link: route.route_link
          }
        }))
        .concat({
          text: htmlDecode(this.getBreadcrumbsCurrent)
        });
    },
    belowAddToCartBlock () {
      const block = this.cmsBlocks.find((_) => _.identifier === 'pdp_below_add_to_cart_block') || {}
      return block.content || ''
    },
    isOnline () {
      return onlineHelper.isOnline;
    },
    getCustomAttributes () {
      return Object.values(this.attributesByCode)
        .filter(a => {
          return (
            a.is_visible &&
            a.is_user_defined &&
            (parseInt(a.is_visible_on_front) ||
              a.is_visible_on_front === true) &&
            this.getCurrentProduct[a.attribute_code]
          );
        })
        .sort((a, b) => {
          return a.attribute_id > b.attribute_id;
        });
    },
    banners () {
      return []
    }
  },
  async mounted () {
    this.reviewsSku = this.getCurrentProduct.parentSku
    await this.loadRetailProducts()
    await this.storeRecentProducts()
  },
  watch: {
    $route () {
      this.handleProductPageView(this.getCurrentProduct)
      this.reviewsSku = this.getCurrentProduct.parentSku
      this.storeRecentProducts()
    },
    isOnline: {
      handler (isOnline) {
        if (isOnline) {
          this.getQuantity();
        }
      }
    }
  },
  async asyncData ({ store, route, context }) {
    if (context) context.output.cacheTags.add('product')
    const product = await store.dispatch('product/loadProduct', {
      parentSku: route.params.parentSku,
      childSku:
        route && route.query && route.query.childSku
          ? route.query.childSku
          : route.params.childSku
    });
    catalogHooksExecutors.productPageVisited(product);
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next();
    } else {
      next(vm => {
        vm.$store.dispatch('stamped-io/list', { sku: vm.getCurrentProduct.parentSku, page: 1, sortBy: config.stampedIo.defaultSortingMethod })
        vm.$store.dispatch('stamped-io/getSummary', { skus: [vm.getCurrentProduct.parentSku] })
        if (!from.name) vm.isRenderedBySSR = true
        vm.isReadyToSendGTMEvents = true
        vm.getQuantity();
      });
    }
  },
  methods: {
    async configurableOptionCallback (variant) {
      const selectedConfiguration = Object.assign({ attribute_code: variant.type }, variant)
      await filterChangedProduct(selectedConfiguration, this.$store, this.$router)
      this.getQuantity();
    },
    async getQuantity () {
      if (this.stock.isLoading) return; // stock info is already loading
      this.stock.isLoading = true;
      try {
        const res = await this.$store.dispatch('stock/check', {
          product: this.getCurrentProduct,
          qty: this.getCurrentProduct.qty
        });
        this.manageQuantity = res.isManageStock;
        this.stock.max = res.isManageStock ? res.qty : null;
      } finally {
        this.stock.isLoading = false;
      }
    },
    async loadRetailProducts () {
      this.youMightLikeCarousel = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.productPageYouMightLikeId);
      this.similarCarousel = await this.$store.dispatch('category-next/loadUpsellProducts', config.retailsProduct.productPageSimilarProductsId);
    },
    storeRecentProducts () {
      const getRecentProducts = JSON.parse(
        localStorage.getItem('recentProducts')
      )
      if (getRecentProducts !== null) {
        const recentProducts = getRecentProducts;
        const doesExist = recentProducts.some(
          (item) => item.id === this.getCurrentProduct.id
        )
        if (!doesExist) {
          if (recentProducts.length > 12) {
            getRecentProducts.pop();
          }
          getRecentProducts.unshift(this.getCurrentProduct);
        }
        localStorage.setItem(
          'recentProducts',
          JSON.stringify(getRecentProducts)
        )
      } else {
        localStorage.setItem('recentProducts', JSON.stringify([this.getCurrentProduct]))
      }
    }
  },
  metaInfo () {
    const storeView = currentStoreView();
    const storeCode = currentStoreView().storeCode || config.defaultStoreCode;
    const baseUrl = config.storeViews[storeCode].url;
    let links = [{ rel: 'canonical', href: baseUrl + formatProductLink(this.getCurrentProduct, storeView.storeCode, true) }]
    this.getProductGallery.forEach((gallery) => {
      links.push({ rel: 'preload', as: 'image', href: gallery.src })
    })
    return {
      title: htmlDecode(
        this.getCurrentProduct.meta_title || this.getCurrentProduct.name
      ),
      meta: this.getCurrentProduct.meta_description
        ? [
          {
            vmid: 'description',
            name: 'description',
            content: htmlDecode(this.getCurrentProduct.meta_description)
          }
        ]
        : [],
      link: links
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
#product {
  box-sizing: border-box;
  @include for-desktop {
    max-width: var(--container-width);
    margin: 0 auto;
  }
}
.product__bottom {
  box-sizing: border-box;
  padding: 0 var(--spacer-sm) var(--spacer-20);
  @include for-desktop {
    max-width: var(--container-width);
  }
}
.banner {
  margin: var(--spacer-xl) 0;
  @include for-desktop {
    margin: var(--spacer-2xl) 0;
  }
}
.banner-reverse {
  flex-direction: row-reverse;
}
</style>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
#chat-button {
    @include for-mobile {
      bottom: 72px !important;
    }
  }
</style>
